import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPassword from "pages/Authentication/ForgetPassword"
import Compareotp from "pages/Authentication/Compareotp"
import Resetpsw from "pages/Authentication/Resetpsw"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import HomeSlide from "pages/Home/HomeSlide"
import Aboutus from "pages/Home/Aboutus"

import Project from "pages/Projects/Project"
import ProjectCat from "pages/Projects/ProjectCat"
import ProjectService from "pages/Projects/ProjectService"

// Settings
import ContactUs from "pages/Home/ContactUs"
import Faqs from "pages/Settings/Faqs"
import PrivicyPolicy from "pages/Settings/PrivicyPolicy"
import RefundPolicy from "pages/Settings/RefundPolicy"
import Terms from "pages/Settings/Terms"

import Product from "pages/Products/Product"
import AddProduct from "pages/Products/AddProduct"
import ProductsCat from "pages/Products/ProductsCat"
import ViewProducts from "pages/Products/ViewProducts"
import EditProducts from "pages/Products/EditProducts"
import ProductsKeyFeatures from "pages/Products/ProductsKeyFeatures"

import Service from "pages/Services/Service"
import AddService from "pages/Services/AddService"
import ServiceCat from "pages/Services/ServiceCat"
import EditService from "pages/Services/EditService"
import ViewServices from "pages/Services/ViewServices"
import ServicePlans from "pages/Services/ServicePlans"

import Testimonials from "pages/Settings/Testimonials"
import OurProfessionals from "pages/Settings/OurProfessionals"
import OurBrands from "pages/Settings/OurBrands"

import HomeContent from "pages/Settings/HomeContent"

import Financing from "pages/Settings/Financing"

import Amc from "pages/Services/Amc"
import AmcService from "pages/Services/AmcService"

import Coupon from "pages/Settings/Coupon"

import ServiceEnquiry from "pages/Services/ServiceEnquiry"
import ProductEnquiry from "pages/Products/ProductEnquiry"
import Enquiry from "pages/Settings/Enquiry"

import CompleatedBookings from "pages/Bookings/CompleatedBookings"
import ConfirmedBookings from "pages/Bookings/ConfirmedBookings"
import PendingBookings from "pages/Bookings/PendingBookings"
import Payments from "pages/Bookings/Payments"

import OnGoingProjects from "pages/Projects/OnGoingProjects"


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/HomeSlide", component: HomeSlide },
  { path: "/Aboutus", component: Aboutus },
  { path: "/ContactUs", component: ContactUs },
  { path: "/profile", component: UserProfile },

  { path: "/profile", component: UserProfile },

  { path: "/ServiceEnquiry", component: ServiceEnquiry },
  { path: "/ProductEnquiry", component: ProductEnquiry },
  { path: "/Enquiry", component: Enquiry },

  { path: "/CompleatedBookings", component: CompleatedBookings },
  { path: "/ConfirmedBookings", component: ConfirmedBookings },
  { path: "/PendingBookings", component: PendingBookings },
  { path: "/Payments", component: Payments },

  //Testimonials
  { path: "/Testimonials", component: Testimonials },
  { path: "/OurProfessionals", component: OurProfessionals },
  { path: "/OurBrands", component: OurBrands },

  { path: "/Coupon", component: Coupon },

  { path: "/Amc", component: Amc },
  { path: "/AmcService", component: AmcService },

  //Product
  { path: "/Product", component: Product },
  { path: "/AddProduct", component: AddProduct },
  { path: "/ProductsCat", component: ProductsCat },
  { path: "/Products", component: ProductsCat },
  { path: "/ViewProducts", component: ViewProducts },
  { path: "/EditProducts", component: EditProducts },
  { path: "/ProductsKeyFeatures", component: ProductsKeyFeatures },

  //Project
  { path: "/ProjectService", component: ProjectService },
  { path: "/ProjectCat", component: ProjectCat },
  { path: "/Project", component: Project },
  { path: "/OnGoingProjects", component: OnGoingProjects },

  //Service
  { path: "/Service", component: Service },
  { path: "/AddService", component: AddService },
  { path: "/ServiceCat", component: ServiceCat },
  { path: "/EditService", component: EditService },
  { path: "/ViewServices", component: ViewServices },
  { path: "/Financing", component: Financing },

  //settings
  { path: "/Faqs", component: Faqs },
  { path: "/ServicePlans", component: ServicePlans },
  { path: "/PrivicyPolicy", component: PrivicyPolicy },
  { path: "/RefundPolicy", component: RefundPolicy },
  { path: "/Terms", component: Terms },
  { path: "/HomeContent", component: HomeContent },

  // this route should be at the end of all other routesside
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/ForgetPassword", component: ForgetPassword },

  { path: "/Compareotp", component: Compareotp },
  { path: "/Resetpsw", component: Resetpsw },
]

export { publicRoutes, authProtectedRoutes }
