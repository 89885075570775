import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Form,
  Label,
  Modal,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"

import axios from "axios"

const Staff = () => {
  const [users, setusers] = useState([])

  const [form, setform] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas
    axios
      .post(
        URLS.GetPendingBookings,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data.bookings)
      })
  }

  const custsearch = e => {
    const myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    const token = datas
    console.log(token)
    axios
      .post(
        URLS.GetPendingBookingsSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setusers(res.data.bookings)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    Get()
  }, [])

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = users.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(users.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [modal_small1, setmodal_small1] = useState(false)

  const [form2, setform2] = useState([])

  const handleChange2 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const getpopup1 = data => {
    setform2(data)
    tog_small1()
  }

  const handleSubmit2 = e => {
    e.preventDefault()
    Editstate1()
  }

  const Editstate1 = () => {
    var token = datas
    var formid = form2._id

    const dataArray = {
      servicemanName: form2.servicemanName,
      servicemanPhone: form2.servicemanPhone,
    }

    axios
      .put(URLS.UpdatePendingBookings + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            Get()
            toast(res.data.message)
            setmodal_small1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="ss air conditioning solutions"
            breadcrumbItem="Bookings"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={form.search}
                          onChange={custsearch}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover bordered responsive>
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Booking Id</th>
                          <th>Booking Date</th>
                          <th>Booking Time</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Address</th>
                          <th>Services Name</th>
                          <th>Services Price</th>
                          <th>Services Quantity</th>
                          <th>Services Type</th>
                          <th>Services Man Details</th>
                          <th>Total</th>
                          <th>Status</th>
                          <th style={{ width: "100px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key}>
                            <th scope="row">
                              {(pageNumber - 1) * 5 + key + 6}
                            </th>
                            <td>{data.bookingId}</td>
                            <td>{data.otherTimings.confirmDate}</td>
                            <td>{data.otherTimings.confirmTime}</td>
                            <td>{data.userName}</td>
                            <td>{data.bookingUserPhone}</td>
                            <td>{data.bookingUserEmail}</td>
                            <td>{data.bookingAdress}</td>

                            <td>
                              {data.services.map(datas => (
                                <>
                                  <p>{datas.name}</p>
                                </>
                              ))}
                            </td>
                            <td>
                              {data.services.map(datas => (
                                <>
                                  <p>{datas.price}</p>
                                </>
                              ))}
                            </td>
                            <td>
                              {data.services.map(datas => (
                                <>
                                  <p>{datas.quantity}</p>
                                </>
                              ))}
                            </td>
                            <td>
                              {data.services.map(datas => (
                                <>
                                  <p>{datas.type}</p>
                                </>
                              ))}
                            </td>
                            <td>
                              {data.servicemanName}/{data.servicemanPhone}
                            </td>
                            <td>{data.totalAmount}</td>
                            <td>{data.status}</td>
                            <td>
                              {data.status == "completed" ? (
                                <></>
                              ) : (
                                <>
                                  {" "}
                                  <Button
                                    onClick={() => {
                                      getpopup1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="success"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="bx bx-repeat"
                                    ></i>
                                  </Button>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Col sm="12">
                      <div
                        className="d-flex mt-3 mb-1"
                        style={{ float: "right" }}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <Modal
          size="md"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Assign To Service Men
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit2(e)
              }}
            >
              <div className="mb-3">
                <Label> Serviceman Name</Label>{" "}
                <span className="text-danger">*</span>
                <input
                  type="text"
                  className="form-control"
                  name="servicemanName"
                  required
                  placeholder="Enter Serviceman Name"
                  onChange={e => {
                    handleChange2(e)
                  }}
                  value={form2.servicemanName}
                />
              </div>

              <div className="mb-3">
                <Label> Serviceman Phone</Label>{" "}
                <span className="text-danger">*</span>
                <input
                  type="number"
                  className="form-control"
                  name="servicemanPhone"
                  required
                  maxLength="10"
                  minLength="10"
                  placeholder="Enter Serviceman Phone"
                  onChange={e => {
                    handleChange2(e)
                  }}
                  value={form2.servicemanPhone}
                />
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Staff
