import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Table,
  Label,
  Input,
  CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import axios from "axios"

function DigitalBrochure() {
  const [show, setshow] = useState(false)

  const [form, setform] = useState([])

  const [forms, setforms] = useState([])

  const handlechange = e => {
    const myform = { ...forms }
    myform[e.target.name] = e.target.value
    setforms(myform)
  }

  useEffect(() => {
    GetAllBroucher()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetAllBroucher = () => {
    var token = datas

    axios
      .post(
        URLS.GetHomeContent,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.homecontent)
        setforms(res.data.homecontent)
      })
  }

  function tog_small() {
    setshow(!show)
  }

  const getpopup1 = () => {
    tog_small()
  }

  const submibooking = e => {
    e.preventDefault()

    changstatus()
  }

  const changstatus = () => {
    var token = datas
    const dataArray = {
      experience: forms.experience,
      projects: forms.projects,
      workers: forms.workers,
      warranty: forms.warranty,
      serviceTitle: forms.serviceTitle,
      serviceDescription: forms.serviceDescription,
      productTitle: forms.productTitle,
      productDescription: forms.productDescription,
      testimonialTitle: forms.testimonialTitle,
      testimonialDescription: forms.testimonialDescription,
      financingTitle: forms.financingTitle,
      financingDescription: forms.financingDescription,
      professionalTitle: forms.professionalTitle,
      professionalDescription: forms.professionalDescription,
      getintouchTitle: forms.getintouchTitle,
      getintouchDescription: forms.getintouchDescription,
      clientTitle: forms.clientTitle,
      clientDescription: forms.clientDescription,
    }

    axios
      .put(URLS.UpdateHomeContent, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setshow(false)
            GetAllBroucher()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
            setIsLoading(false)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="ss air conditioning solutions"
            breadcrumbItem="Home Content"
          />
          {show == true ? (
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Add Product Category</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      submibooking(e)
                    }}
                  >
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Experience <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Experience"
                            required
                            value={forms.experience}
                            name="experience"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Projects <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Projects"
                            required
                            value={forms.projects}
                            name="projects"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Workers <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Workers"
                            required
                            value={forms.workers}
                            name="workers"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Service Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Service Title"
                            required
                            value={forms.serviceTitle}
                            name="serviceTitle"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Service Description{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            rows="3"
                            className="form-control "
                            id="basicpill-firstname-input1"
                            placeholder="Enter Service Description"
                            required
                            value={forms.serviceDescription}
                            name="serviceDescription"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Product Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Product Title"
                            required
                            value={forms.productTitle}
                            name="productTitle"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Product Description{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            rows="3"
                            className="form-control "
                            id="basicpill-firstname-input1"
                            placeholder="Enter Service Description"
                            required
                            value={forms.productDescription}
                            name="productDescription"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Testimonial Title{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Testimonial Title"
                            required
                            value={forms.testimonialTitle}
                            name="testimonialTitle"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Testimonial Description{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            rows="3"
                            className="form-control "
                            id="basicpill-firstname-input1"
                            placeholder="Enter  Testimonial Description"
                            required
                            value={forms.testimonialDescription}
                            name="testimonialDescription"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Financing Title{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Financing Title"
                            required
                            value={forms.financingTitle}
                            name="financingTitle"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Financing Description{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            rows="3"
                            className="form-control "
                            id="basicpill-firstname-input1"
                            placeholder="Enter Financing Description"
                            required
                            value={forms.financingDescription}
                            name="financingDescription"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Professional Title{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Professional Title"
                            required
                            value={forms.professionalTitle}
                            name="professionalTitle"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Professional Description{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            rows="3"
                            className="form-control "
                            id="basicpill-firstname-input1"
                            placeholder="Enter Professional Description"
                            required
                            value={forms.professionalDescription}
                            name="professionalDescription"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Get in touch Title{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Get in touch Title"
                            required
                            value={forms.getintouchTitle}
                            name="getintouchTitle"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Get in touch Description{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            rows="3"
                            className="form-control "
                            id="basicpill-firstname-input1"
                            placeholder="Enter   Get in touch Description"
                            required
                            value={forms.getintouchDescription}
                            name="getintouchDescription"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Client Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Client Title"
                            required
                            value={forms.clientTitle}
                            name="clientTitle"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Client Description{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            rows="3"
                            className="form-control "
                            id="basicpill-firstname-input1"
                            placeholder="Enter Client Description"
                            required
                            value={forms.clientDescription}
                            name="clientDescription"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div style={{ float: "right" }}>
                      <Button color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  <Row>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Button
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Edit Booking"
                          onClick={() => {
                            getpopup1(form)
                          }}
                          className="mr-5 mb-1 m-1 mt-3"
                          color="success"
                          outline
                        >
                          <i className="bx bx-edit text-dark "></i>
                          <span>Edit Home Content</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Row>
                    <div>
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr>
                              <th className="text-end">Experience </th>
                              <td>{form.experience}</td>
                            </tr>
                            <tr>
                              <th className="text-end">Projects </th>
                              <td>{form.projects}</td>
                            </tr>
                            <tr>
                              <th className="text-end">Workers</th>
                              <td>{form.workers}</td>
                            </tr>
                            <tr>
                              <th className="text-end">Service Title</th>
                              <td>{form.serviceTitle}</td>
                            </tr>
                            <tr>
                              <th className="text-end">Service Description</th>
                              <td>{form.serviceDescription}</td>
                            </tr>
                            <tr>
                              <th className="text-end">Product Title</th>
                              <td>{form.productTitle}</td>
                            </tr>
                            <tr>
                              <th className="text-end">Product Description</th>
                              <td>{form.productDescription}</td>
                            </tr>
                            <tr>
                              <th className="text-end">Testimonial Title</th>
                              <td>{form.testimonialTitle}</td>
                            </tr>
                            <tr>
                              <th className="text-end">
                                Testimonial Description
                              </th>
                              <td>{form.testimonialDescription}</td>
                            </tr>
                            <tr>
                              <th className="text-end">Financing Title</th>
                              <td>{form.financingTitle}</td>
                            </tr>
                            <tr>
                              <th className="text-end">
                                Financing Description
                              </th>
                              <td>{form.financingDescription}</td>
                            </tr>
                            <tr>
                              <th className="text-end">Professional Title</th>
                              <td>{form.professionalTitle}</td>
                            </tr>
                            <tr>
                              <th className="text-end">
                                Professional Description
                              </th>
                              <td> {form.professionalDescription}</td>
                            </tr>

                            <tr>
                              <th className="text-end">Get in touch Title</th>
                              <td>{form.getintouchTitle}</td>
                            </tr>
                            <tr>
                              <th className="text-end">
                                Get in touch Description
                              </th>
                              <td>{form.getintouchDescription}</td>
                            </tr>

                            <tr>
                              <th className="text-end">Client Title</th>
                              <td>{form.clientTitle}</td>
                            </tr>
                            <tr>
                              <th className="text-end">Client Description</th>
                              <td>{form.clientDescription}</td>
                            </tr>
                          </thead>
                        </Table>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default DigitalBrochure
