const Url = "https://api.ssairconditioners.com/"

export const URLS = {
  Base: Url,

  //profile
  getProfile: Url + "v1/ssairconditionsapi/admin/auth/getadminprofile",
  UpdateProfile: Url + "v1/ssairconditionsapi/admin/auth/editprofile",
  UpdateImage: Url + "v1/ssairconditionsapi/admin/auth/updateprofilepic",
  ChangePass: Url + "v1/ssairconditionsapi/admin/auth/changeadminpass",

  //HomeSliders
  AddHomeslides: Url + "v1/ssairconditionsapi/admin/homeslider/addhomeslider",
  GetHomeslides:
    Url + "v1/ssairconditionsapi/admin/homeslider/getallhomesliders",
  GetHomeslidesSearch:
    Url +
    "v1/ssairconditionsapi/admin/homeslider/getallhomesliders?searchQuery=",
  UpdateHomeslides:
    Url + "v1/ssairconditionsapi/admin/homeslider/edithomeslider/",
  DeleteHomeslides:
    Url + "v1/ssairconditionsapi/admin/homeslider/deletehomeslider/",

  //About
  GetAbout: Url + "v1/ssairconditionsapi/admin/aboutus/getaboutus",
  UpdatAbout: Url + "v1/ssairconditionsapi/admin/aboutus/editaboutus",

  //ContactUs
  GetContactUs: Url + "v1/ssairconditionsapi/admin/contactus/getcontactus",
  UpdatContactUs: Url + "v1/ssairconditionsapi/admin/contactus/editcontactus",

  //Settings
  GetSetting: Url + "v1/ssairconditionsapi/admin/setting/getsetting",
  UpdatePRIVACYPOLICY:
    Url + "v1/ssairconditionsapi/admin/setting/editprivacypolicy",
  UpdateTERMSANDCONDITION:
    Url + "v1/ssairconditionsapi/admin/setting/edittermsandcondition",
  UpdateRefundPolicy:
    Url + "v1/ssairconditionsapi/admin/setting/editrefundpolicy",

  //Faqs
  AddFaqs: Url + "v1/ssairconditionsapi/admin/faq/addfaq",
  GetFaqs: Url + "v1/ssairconditionsapi/admin/faq/getallfaqs",
  GetFaqsSearch:
    Url + "v1/ssairconditionsapi/admin/faq/getallfaqs?searchQuery=",
  UpdateFaqs: Url + "v1/ssairconditionsapi/admin/faq/editfaq",
  InActiveFaqs: Url + "v1/ssairconditionsapi/admin/faq/deletefaq",

  //Product Category
  AddProductCategory:
    Url + "v1/ssairconditionsapi/admin/productcategory/addproductcategory",
  GetProductCategory:
    Url + "v1/ssairconditionsapi/admin/productcategory/getallproductcategorys",
  GetProductCategorySearch:
    Url +
    "v1/ssairconditionsapi/admin/productcategory/getallproductcategorys?searchQuery=",
  UpdateProductCategory:
    Url + "v1/ssairconditionsapi/admin/productcategory/editproductcategory/",
  DeleteProductCategory:
    Url + "v1/ssairconditionsapi/admin/productcategory/deleteproductcategory/",

  //Product Key Feature
  AddProductKeyFeature:
    Url + "v1/ssairconditionsapi/admin/splitkeyfeature/addsplitkeyfeature",
  GetProductKeyFeature:
    Url + "v1/ssairconditionsapi/admin/splitkeyfeature/getallsplitkeyfeatures",
  GetProductKeyFeatureSearch:
    Url +
    "v1/ssairconditionsapi/admin/splitkeyfeature/getallsplitkeyfeatures?searchQuery=",
  UpdateProductKeyFeature:
    Url + "v1/ssairconditionsapi/admin/splitkeyfeature/editsplitkeyfeature/",
  DeleteProductKeyFeature:
    Url + "v1/ssairconditionsapi/admin/splitkeyfeature/deletesplitkeyfeature/",

  //Product
  AddProduct: Url + "v1/ssairconditionsapi/admin/product/addproduct",
  GetProduct: Url + "v1/ssairconditionsapi/admin/product/getallproducts",
  GetProductSearch:
    Url + "v1/ssairconditionsapi/admin/product/getallproducts?searchQuery=",
  UpdateProduct: Url + "v1/ssairconditionsapi/admin/product/editproduct/",
  DeleteProduct: Url + "v1/ssairconditionsapi/admin/product/deleteproduct/",
  GetoneProduct: Url + "v1/ssairconditionsapi/admin/product/getproductbyid",

  //ServiceCategory
  AddServiceCategory: Url + "v1/ssairconditionsapi/admin/service/addservice",
  GetServiceCategory: Url + "v1/ssairconditionsapi/admin/service/getallserives",
  GetServiceCategorySearch:
    Url + "v1/ssairconditionsapi/admin/service/getallserives?searchQuery=",
  UpdateServiceCategory:
    Url + "v1/ssairconditionsapi/admin/service/editservice/",
  DeleteServiceCategory:
    Url + "v1/ssairconditionsapi/admin/service/deleteservice/",
  GetoneServiceCategory:
    Url + "v1/ssairconditionsapi/admin/serviceprocess/getserviceprocessByid",

  //Service
  AddService:
    Url + "v1/ssairconditionsapi/admin/serviceprocess/addserviceprocess",
  GetService:
    Url + "v1/ssairconditionsapi/admin/serviceprocess/getallserviceprocess",
  GetServiceSearch:
    Url +
    "v1/ssairconditionsapi/admin/serviceprocess/getallserviceprocess?searchQuery=",
  UpdateService:
    Url + "v1/ssairconditionsapi/admin/serviceprocess/editserviceprocess/",
  DeleteService:
    Url + "v1/ssairconditionsapi/admin/serviceprocess/deleteserviceprocesss/",
  GetoneService: Url + "v1/ssairconditionsapi/admin/service/getserviceByid",

  //Coupon
  AddCoupon: Url + "v1/ssairconditionsapi/admin/coupon/addcoupon",
  GetCoupon: Url + "v1/ssairconditionsapi/admin/coupon/getallcoupons",
  GeCouponSearch:
    Url + "v1/ssairconditionsapi/admin/coupon/getallcoupons?searchQuery=",
  UpdateCoupon: Url + "v1/ssairconditionsapi/admin/coupon/editcoupon",
  DeleteCoupon: Url + "v1/ssairconditionsapi/admin/coupon/deletecoupon",

  //Service Plans
  AddServicePlans:
    Url + "v1/ssairconditionsapi/admin/servicepaln/addserviceplan",
  GetServicePlans:
    Url + "v1/ssairconditionsapi/admin/servicepaln/getallserviceplans",
  GetServicePlansSearch:
    Url +
    "v1/ssairconditionsapi/admin/servicepaln/getallserviceplans?searchQuery=",
  UpdateServicePlans:
    Url + "v1/ssairconditionsapi/admin/servicepaln/editserviceplan/",
  DeleteServicePlans:
    Url + "v1/ssairconditionsapi/admin/servicepaln/deleteserviceplan/",

  //ProjectCategory
  AddProjectCategory:
    Url + "v1/ssairconditionsapi/admin/projectcategory/addprojectcategory",
  GetProjectCategory:
    Url + "v1/ssairconditionsapi/admin/projectcategory/getallprojectcategory",
  GetProjectCategorySearch:
    Url +
    "v1/ssairconditionsapi/admin/projectcategory/getallprojectcategory?searchQuery=",
  UpdateProjectCategory:
    Url + "v1/ssairconditionsapi/admin/projectcategory/editprojectcategory/",
  DeleteProjectCategory:
    Url + "v1/ssairconditionsapi/admin/projectcategory/deleteprojectcategory/",

  //Projects Services
  AddProjectsServices:
    Url + "v1/ssairconditionsapi/admin/projectservice/addprojectservice",
  GetProjectsServices:
    Url + "v1/ssairconditionsapi/admin/projectservice/getallprojetservices",
  GetProjectsServicesSearch:
    Url +
    "v1/ssairconditionsapi/admin/projectservice/getallprojetservices?searchQuery=",
  UpdateProjectsServices:
    Url + "v1/ssairconditionsapi/admin/projectservice/editprojectservice/",
  DeleteProjectsServices:
    Url + "v1/ssairconditionsapi/admin/projectservice/deleteprojectservice/",

  //Projects
  AddProjects: Url + "v1/ssairconditionsapi/admin/project/addproject",
  GetProjects: Url + "v1/ssairconditionsapi/admin/project/getallprojects",
  GetProjectsSearch:
    Url + "v1/ssairconditionsapi/admin/project/getallprojects?searchQuery=",
  UpdateProjects: Url + "v1/ssairconditionsapi/admin/project/editproject/",
  DeleteProjects: Url + "v1/ssairconditionsapi/admin/project/delteproject/",

  //Testimonials
  AddTestimonials:
    Url + "v1/ssairconditionsapi/admin/testimonial/addtestimonial",
  GetTestimonials:
    Url + "v1/ssairconditionsapi/admin/testimonial/getalltestimonials",
  GetTestimonialsSearch:
    Url +
    "v1/ssairconditionsapi/admin/testimonial/getalltestimonials?searchQuery=",
  UpdateTestimonials:
    Url + "v1/ssairconditionsapi/admin/testimonial/edittestimonial/",
  DeleteTestimonials:
    Url + "v1/ssairconditionsapi/admin/testimonial/deletetestimonial/",

  //PROFESSIONALS
  AddPROFESSIONALS:
    Url + "v1/ssairconditionsapi/admin/professional/addprofessional",
  GetPROFESSIONALS:
    Url + "v1/ssairconditionsapi/admin/professional/getallprofessionals",
  GetPROFESSIONALSSearch:
    Url +
    "v1/ssairconditionsapi/admin/professional/getallprofessionals?searchQuery=",
  UpdatePROFESSIONALS:
    Url + "v1/ssairconditionsapi/admin/professional/editprofessional/",
  DeletePROFESSIONALS:
    Url + "v1/ssairconditionsapi/admin/professional/deleteprofessional/",

  //CLIENT
  AddCLIENT: Url + "v1/ssairconditionsapi/admin/client/addclient",
  GetCLIENT: Url + "v1/ssairconditionsapi/admin/client/getallclients",
  GetCLIENTSearch:
    Url + "v1/ssairconditionsapi/admin/client/getallclients?searchQuery=",
  UpdateCLIENT: Url + "v1/ssairconditionsapi/admin/client/editclient/",
  DeleteCLIENT: Url + "v1/ssairconditionsapi/admin/client/deleteclient/",

  //HomeContent
  GetHomeContent:
    Url + "v1/ssairconditionsapi/admin/homecontent/gethomecontent",
  UpdateHomeContent:
    Url + "v1/ssairconditionsapi/admin/homecontent/edithomecontent/",

  //HomeContent
  AddFinancing: Url + "v1/ssairconditionsapi/admin/financing/addfinancing",
  GetFinancing: Url + "v1/ssairconditionsapi/admin/financing/getallfinancing",
  GetFinancingSearch:
    Url + "v1/ssairconditionsapi/admin/financing/getallfinancing?searchQuery=",
  UpdateFinancing: Url + "v1/ssairconditionsapi/admin/financing/editfinancing/",
  DeleteFinancing:
    Url + "v1/ssairconditionsapi/admin/financing/deletefinancing/",

  //Amc
  GetAmc: Url + "v1/ssairconditionsapi/admin/amc/getamc",
  UpdateAmc: Url + "v1/ssairconditionsapi/admin/amc/editamc",

  //AmcServices
  AddAmcServices: Url + "v1/ssairconditionsapi/admin/amcservice/addamcservice",
  GetAmcServices:
    Url + "v1/ssairconditionsapi/admin/amcservice/getallamcservices",
  GetAmcServicesSearch:
    Url +
    "v1/ssairconditionsapi/admin/amcservice/getallamcservices?searchQuery=",
  UpdateAmcServices:
    Url + "v1/ssairconditionsapi/admin/amcservice/editamcservice/",
  DeleteAmcServices:
    Url + "v1/ssairconditionsapi/admin/amcservice/deleteamcservice/",

  //Enquiry
  GetEnquiry: Url + "v1/ssairconditionsapi/admin/getallenquiry",
  GetEnquirySearch:
    Url + "v1/ssairconditionsapi/admin/getallenquiry?searchQuery=",
  DeleteEnquiry: Url + "v1/ssairconditionsapi/admin/deleteenquiry/",

  //ServiceEnquiry
  GetServiceEnquiry: Url + "v1/ssairconditionsapi/admin/getallserviceenquirys",
  GetServiceEnquirySearch:
    Url + "v1/ssairconditionsapi/admin/getallserviceenquirys?searchQuery=",
  DeleteServiceEnquiry:
    Url + "v1/ssairconditionsapi/admin/deleteserviceenquiry/",

  //ProductEnquiry
  GetProductEnquiry: Url + "v1/ssairconditionsapi/admin/getallproductenquirys",
  GetProductEnquirySearch:
    Url + "v1/ssairconditionsapi/admin/getallproductenquirys?searchQuery=",
  DeleteProductEnquiry:
    Url + "v1/ssairconditionsapi/admin/deleteproductenquiry/",

  //PendingBookings
  GetPendingBookings: Url + "v1/ssairconditionsapi/admin/getallbookings",
  GetPendingBookingsSearch:
    Url + "v1/ssairconditionsapi/admin/getallbookings?searchQuery=",
  UpdatePendingBookings: Url + "v1/ssairconditionsapi/admin/updateserviceman",

  //Payments
  GetPayments: Url + "v1/ssairconditionsapi/admin/getallpayments",
  GetCompleatedBookingsSearch:
    Url + "v1/ssairconditionsapi/admin/getallpayments?searchQuery=",

  //Dashboard
  GetDashboard: Url + "v1/ssairconditionsapi/admin/getdashboarddata",
}
