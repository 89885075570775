import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Table,
  Label,
  Input,
  CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import axios from "axios"

function DigitalBrochure() {
  const [files, setFiles] = useState([])

  const [files1, setFiles1] = useState([])

  const [inputList, setInputList] = useState([{ title: "", description: "" }])

  const [show, setshow] = useState(false)

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (
      type == "jpg" ||
      type == "jpeg" ||
      type == "png" ||
      type == "JPG" ||
      type == "JPEG" ||
      type == "PNG" ||
      type == "JPEG" ||
      type == "WEBP" ||
      type == "webp"
    ) {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (
      type == "jpg" ||
      type == "jpeg" ||
      type == "png" ||
      type == "JPG" ||
      type == "JPEG" ||
      type == "PNG" ||
      type == "JPEG" ||
      type == "WEBP" ||
      type == "webp"
    ) {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const [form, setform] = useState([])

  const [forms, setforms] = useState([])

  const handlechange = e => {
    const myform = { ...forms }
    myform[e.target.name] = e.target.value
    setforms(myform)
  }

  useEffect(() => {
    GetAllBroucher()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetAllBroucher = () => {
    var token = datas

    axios
      .post(
        URLS.GetAbout,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.aboutus)
        setforms(res.data.aboutus)
        setInputList(res.data.aboutus.whyWeChooseUs)
      })
  }

  function tog_small() {
    setshow(!show)
  }

  const getpopup1 = () => {
    tog_small()
  }

  const submibooking = e => {
    e.preventDefault()
    changstatus()
  }

  const changstatus = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("title", forms.title)
    dataArray.append("description", forms.description)
    dataArray.append("largeDescription", "")
    dataArray.append("whyWeChooseUs", JSON.stringify(inputList))

    for (let i = 0; i < files.length; i++) {
      dataArray.append("image", files[i])
    }

    for (let i = 0; i < files1.length; i++) {
      dataArray.append("image1", files1[i])
    }

    axios
      .put(URLS.UpdatAbout, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setshow(false)
            GetAllBroucher()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { title: "", description: "" }])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="ss air conditioning solutions"
            breadcrumbItem="About Us"
          />
          {show == true ? (
            <Col md={12}>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      submibooking(e)
                    }}
                  >
                    <Row>
                      <CardTitle className="mb-3">Edit About Us</CardTitle>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Title"
                            required
                            value={forms.title}
                            name="title"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Image <span className="text-danger">* 400*600</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            name="image"
                            value={files.image}
                            onChange={changeHandler}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Description <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            type="text"
                            rows="6"
                            className="form-control "
                            id="basicpill-firstname-input1"
                            placeholder="Enter Service Description"
                            required
                            value={forms.description}
                            name="description"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <CardTitle className="mb-3">
                        Edit Why We Choose Us
                      </CardTitle>
                      <Col md={4}>
                        <div className="mb-3 mt-2">
                          <Label for="basicpill-firstname-input1">
                            Image <span className="text-danger">* 400*600</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            name="image"
                            value={files1.image}
                            onChange={changeHandler1}
                          />
                        </div>
                      </Col>
                      <div>
                        <Row>
                          {inputList.map((x, i) => {
                            return (
                              <>
                                <Row>
                                  <div key={i} className="box row">
                                    <Col md="5">
                                      <Label>Title</Label>
                                      <Input
                                        type="text"
                                        required
                                        name="title"
                                        placeholder="Enter Title"
                                        value={x.title}
                                        onChange={e => handleInputChange(e, i)}
                                      />
                                    </Col>
                                    <Col md="5">
                                      <Label>Description</Label>
                                      <textarea
                                        type="text"
                                        rows="1"
                                        className="form-control "
                                        id="basicpill-firstname-input1"
                                        placeholder="Enter Description"
                                        required
                                        value={x.description}
                                        name="description"
                                        onChange={e => {
                                          handleInputChange(e, i)
                                        }}
                                      />
                                    </Col>

                                    <Col md="2">
                                      <div className="btn-box">
                                        {inputList.length !== 1 && (
                                          <button
                                            className="mr10 btn btn-outline-danger btn-sm m-1"
                                            type="button"
                                            onClick={() => handleRemoveClick(i)}
                                          >
                                            Remove
                                            <i className="bx bx-x-circle"></i>
                                          </button>
                                        )}
                                        {inputList.length - 1 === i && (
                                          <button
                                            className="btn btn-sm btn-outline-info m-1"
                                            onClick={handleAddClick}
                                          >
                                            Add
                                            <i className="bx bx-plus-circle"></i>
                                          </button>
                                        )}
                                      </div>
                                    </Col>
                                  </div>
                                </Row>
                              </>
                            )
                          })}
                        </Row>
                      </div>
                    </Row>
                    <div style={{ float: "right" }}>
                      <Button color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  <Row>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Button
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Edit Booking"
                          onClick={() => {
                            getpopup1()
                          }}
                          className="mr-5 mb-1 m-1 mt-3"
                          color="success"
                          outline
                        >
                          <i className="bx bx-edit text-dark "></i>
                          <span>Edit About Us</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h4 className=" text-dark">About Us</h4>
                  <Row>
                    <div>
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>Title </th>
                              <td>{form.title}</td>
                            </tr>
                            <tr className="text-center">
                              <th>Image</th>
                              <td>
                                <img
                                  src={URLS.Base + form.image}
                                  width="300px"
                                ></img>
                              </td>
                            </tr>
                            <tr className="text-center">
                              <th>Description</th>
                              <td>{form.description}</td>
                            </tr>
                          </thead>
                        </Table>
                      </div>
                    </div>
                  </Row>

                  <h4 className="mt-5 text-dark"> Why We Choose Us</h4>
                  <Row>
                    <div>
                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>Image</th>
                              <td>
                                <img
                                  src={URLS.Base + form.image1}
                                  width="300px"
                                ></img>
                              </td>
                            </tr>
                            <tr className="text-center">
                              <th>Title / Description</th>
                              <td>
                                {inputList.map((x, i) => {
                                  return (
                                    <>
                                      <p>
                                        {i + 1}) {x.title} <br></br>
                                        {x.description}
                                      </p>
                                    </>
                                  )
                                })}
                              </td>
                            </tr>
                          </thead>
                        </Table>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default DigitalBrochure
