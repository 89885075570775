import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Select from "react-select"
import { URLS } from "../../Url"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])

  const [selectedMulti, setselectedMulti] = useState([])

  function handleMulti(data) {
    setselectedMulti(data)
  }

  const [text, setText] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  const history = useHistory()
  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("name", form.name)
    dataArray.append("modelName", form.modelName)
    dataArray.append("refrigant", form.refrigant)
    dataArray.append("tonnage", form.tonnage)
    dataArray.append("rating", form.rating)
    dataArray.append("price", form.price)
    dataArray.append("description", form.description)
    dataArray.append("productcategoryId", form.productcategoryId)
    dataArray.append("specifications", text)

    dataArray.append("keyFeatures", JSON.stringify(selectedMulti))

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("image", selectedFiles[i])
    }
    axios
      .post(URLS.AddProduct, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Product")
            clearForm()
            setSelectedOptions("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Specifications, setSpecifications] = useState([])

  useEffect(() => {
    GetSpecifications()
    GetProducts()
  }, [])

  const GetSpecifications = () => {
    var token = datas

    axios
      .post(
        URLS.GetProductKeyFeature,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setSpecifications(res.data.splitkeyfeatures)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Products, setProducts] = useState([])

  const GetProducts = () => {
    var token = datas
    axios
      .post(
        URLS.GetProductCategory,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setProducts(res.data.productcategorys)
      })
  }

  const options = Specifications.map(data => ({
    value: data._id,
    label: data.name,
    image: data.image
  }))

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const clearForm = () => {
    setform({
      company_name: "",
    })
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ss air conditioning solutions" breadcrumbItem="Add Product" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody> 
                <Row className="mt-2">
                  <Col lg="6">
                    <h5
                      className="mb-4"
                      style={{ color: "#0079FF", fontWeight: "bold" }}
                    >
                      General Information
                    </h5>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Product Category <span className="text-danger">*</span>
                      </Label>
                      <select
                        value={form.productcategoryId}
                        name="productcategoryId"
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {Products.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Product Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter  Product Name"
                        required
                        value={form.name}
                        name="name"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Model <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="title"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Model"
                        required
                        value={form.modelName}
                        name="modelName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Refrigant <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="title"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Refrigant"
                        required
                        value={form.refrigant}
                        name="refrigant"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Tonnage <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="title"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Tonnage"
                        required
                        value={form.tonnage}
                        name="tonnage"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Product Price <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Product Price"
                        required
                        value={form.price}
                        name="price"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="text-center m-4">
                      <h5 style={{ fontWeight: "bold" }}>Product Image</h5>

                      <div className="w-50 m-auto">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()}  />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                          <span>
                            Image format - jpg, png, jpeg, gif Image Size -
                            maximum size 2 MB Image Ratio - 1:1
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Rating <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form.rating}
                        name="rating"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value=""> Select Rating</option>
                        <option value="1">1</option>
                        <option value="2"> 2</option>
                        <option value="3"> 3</option>
                        <option value="4"> 4</option>
                        <option value="5"> 5</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <Label>Key Features</Label>
                    <span className="text-danger">*</span>
                    <Select
                      name="userList"
                      value={selectedMulti}
                      onChange={handleMulti}
                      options={options}
                      required
                      isMulti
                    />
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Description <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="6"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Description"
                        required
                        value={form.description}
                        name="description"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                {" "}
                <Row>
                  <Col md="12" className="mt-3 mb-3">
                    <Label>Specifications</Label>
                    <CKEditor
                      editor={ClassicEditor}
                      id="header"
                      data={text}
                      onReady={editor => {
                        console.log("Editor is ready to use!", editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setText(data)
                      }}
                    />
                  </Col>{" "}
                </Row>{" "}
              </CardBody>
            </Card>

            <Row>
              <Col md={12}>
                <div className=" mb-2" style={{ float: "right" }}>
                  <button
                    type="submit"
                    style={{ width: "120px" }}
                    className="btn btn-info m-1"
                  >
                    Submit <i className="fas fa-cHCUk-circle"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
