import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const Banner = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [show, setshow] = useState(false)
  const [text, setText] = useState([])
  const [text1, setText1] = useState([])
  const [banner, setbanner] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  useEffect(() => {
    GetAllBanners()
  }, [])

  const [Files, setFiles] = useState("")
  const [Files1, setFiles1] = useState("")

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const handleSubmit = e => {
    e.preventDefault()
    AddBanner()
  }

  const AddBanner = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("title", form.title)
    dataArray.append("description", form.description)
    dataArray.append("expiredDate", form.expiredDate)
    dataArray.append("largeDescription", text)
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("image", Files[i])
    }

    axios
      .post(URLS.AddFinancing, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setshow(false)
            GetAllBanners()
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const EditBanner = () => {
    var token = datas
    var formid = form1._id
    const dataArray = new FormData()
    dataArray.append("title", form1.title)
    dataArray.append("description", form1.description)
    dataArray.append("expiredDate", form1.expiredDate)
    dataArray.append("largeDescription", text1)

    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("image", Files1[i])
    }

    axios
      .put(URLS.UpdateFinancing + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const DeleteBanner = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteFinancing + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteBanner(data)
    }
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditBanner()
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        URLS.GetFinancing,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.finacings)
      })
  }

  const clearForm = () => {
    setform({
      title: "",
      description: "",
      expiredDate: "",
    })
    setText("")
    setFiles({
      image: "",
    })
  }

  const getpopup = data => {
    setform1(data)
    setText1(data.largeDescription)
    tog_small()
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        URLS.GetFinancingSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.finacings)
      })
  }
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="ss air conditioning solutions"
            breadcrumbItem="Financing"
          />
          <Row>
            {show == true ? (
              <Col md={12}>
                <Card>
                  <CardHeader className="bg-white">
                    <CardTitle>Add Financing</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        handleSubmit(e)
                      }}
                    >
                      <Row>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Title <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter Title"
                              required
                              value={form.title}
                              name="title"
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Expire Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter Expire Date"
                              required
                              value={form.expiredDate}
                              name="expiredDate"
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Image
                              <span className="text-danger">* 400*600</span>
                            </Label>
                            <Input
                              type="file"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              name="image"
                              required
                              value={Files.image}
                              onChange={changeHandler}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Description <span className="text-danger">*</span>
                            </Label>
                            <textarea
                              type="text"
                              rows="3"
                              className="form-control "
                              id="basicpill-firstname-input1"
                              placeholder="Enter Service Description"
                              required
                              value={form.description}
                              name="description"
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="12" className="mt-3 mb-3">
                          <Label>Large Description</Label>
                          <CKEditor
                            editor={ClassicEditor}
                            id="header"
                            data={text}
                            onReady={editor => {
                              console.log("Editor is ready to use!", editor)
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              setText(data)
                            }}
                          />
                        </Col>
                      </Row>
                      <div style={{ float: "right" }}>
                        <Button color="primary" type="submit">
                          Submit <i className="fas fa-check-circle"></i>
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Financing List</CardTitle>
                </CardHeader>
                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <div style={{ float: "right" }}></div>
                      <Row>
                        <Col>
                          <Button
                            onClick={() => {
                              setshow(!show)
                            }}
                            color="primary"
                          >
                            Add Financing
                            <i className="bx bx-user-plus"></i>
                          </Button>
                        </Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Search.."
                              value={search.search}
                              onChange={searchAll}
                              name="search"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Table className="table table-bordered mb-4 mt-5">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Title</th>
                            <th>Image</th>
                            <th>Description</th>
                            <th>Financing </th>
                            <th>Expire Price</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key}>
                              <th>{(pageNumber - 1) * 5 + key + 6}</th>
                              <td>{data.title}</td>
                              <td>
                                <img
                                  src={URLS.Base + data.image}
                                  width="100px"
                                ></img>
                              </td>
                              <td>{data.description}</td>
                              <td>
                                <div
                                  className="mt-2"
                                  dangerouslySetInnerHTML={{
                                    __html: data.largeDescription,
                                  }}
                                ></div>
                              </td>
                              <td>{data.expiredDate}</td>
                              <td>
                                <Button
                                  onClick={() => {
                                    getpopup(data)
                                  }}
                                  className="mr-2"
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  color="success"
                                  outline
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="xl"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Financing
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Title <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      required
                      value={form1.title}
                      name="title"
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Expire Date <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Expire Date"
                      required
                      value={form1.expiredDate}
                      name="expiredDate"
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Image <span className="text-danger">* 400*600</span>
                    </Label>
                    <Input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      name="image"
                      value={Files1.image}
                      onChange={changeHandler1}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <textarea
                      type="text"
                      rows="3"
                      className="form-control "
                      id="basicpill-firstname-input1"
                      placeholder="Enter Service Description"
                      required
                      value={form1.description}
                      name="description"
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="12" className="mt-3 mb-3">
                  <Label>Large Description</Label>
                  <CKEditor
                    editor={ClassicEditor}
                    id="header"
                    data={text1}
                    onReady={editor => {
                      console.log("Editor is ready to use!", editor)
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setText1(data)
                    }}
                  />
                </Col>
              </Row>
              <div style={{ float: "right" }}>
                <Button color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Banner
